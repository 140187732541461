import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  MapEntry,
  Nullable,
  Nullish,
  ResourceType,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import {
  getInvestment,
  getPost,
  getPostPreviewById,
  getStock,
} from '@/services/requests';
import { Investment, PostFull, PostShort, Stock } from '@/typings/model';
import { LangType } from '@/typings/common';

const postLoader = createResourceLoader<Nullable<PostFull>>(null);
const postPreviewLoader = createResourceLoader<Nullable<Array<PostShort>>>([]);

type StateType = {
  postMap: Record<string, ResourceType<Nullable<PostFull>>>;
  postPreviewMap: Record<string, ResourceType<Nullable<Array<PostShort>>>>;
  stockMap: Record<string, Nullable<Stock>>;
  investmentMap: Record<string, Nullable<Investment>>;
};

const initialState: StateType = {
  postMap: {},
  postPreviewMap: {},
  stockMap: {},
  investmentMap: {},
};

const postSlice = createSlice({
  name: 'pages/post',
  initialState,
  reducers: {
    postRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.postMap[action.payload.key] = postLoader.pending();
    },
    postRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<string, PostFull>>
    ) {
      state.postMap[action.payload.key] = postLoader.fulfill(
        action.payload.value
      );
    },
    postRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.postMap[action.payload.key] = postLoader.reject();
    },
    postPreviewRequestPending(state, action: PayloadAction<{ key: number }>) {
      state.postPreviewMap[action.payload.key] = postPreviewLoader.pending();
    },
    postPreviewRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<number, Array<PostShort>>>
    ) {
      state.postPreviewMap[action.payload.key] = postPreviewLoader.fulfill(
        action.payload.value
      );
    },
    postPreviewRequestRejected(state, action: PayloadAction<{ key: number }>) {
      state.postPreviewMap[action.payload.key] = postPreviewLoader.reject();
    },
    stockAdded(
      state,
      action: PayloadAction<MapEntry<string, Nullable<Stock>>>
    ) {
      state.stockMap[action.payload.key] = action.payload.value;
    },
    investmentAdded(
      state,
      action: PayloadAction<MapEntry<string, Nullable<Investment>>>
    ) {
      state.investmentMap[action.payload.key] = action.payload.value;
    },
  },
});

const { actions, reducer } = postSlice;
export const {
  postRequestPending,
  postRequestFulfilled,
  postRequestRejected,
  postPreviewRequestPending,
  postPreviewRequestFulfilled,
  postPreviewRequestRejected,
  stockAdded,
  investmentAdded,
} = actions;

export default reducer;

export function getPostThunk(
  urlAlias: string,
  lang: LangType,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<PostFull>>> {
  return async (dispatch, getState) => {
    try {
      const post = selectPostResource(getState(), urlAlias);

      if (!options?.shouldInvalidate && post) {
        return post.data;
      }

      dispatch(postRequestPending({ key: urlAlias }));

      const response = await getPost(urlAlias, lang);

      dispatch(postRequestFulfilled({ key: urlAlias, value: response.data }));

      return response.data;
    } catch (error) {
      dispatch(postRequestRejected({ key: urlAlias }));

      return null;
    }
  };
}

export function getPostPreviewThunk(
  id: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<Array<PostShort>>>> {
  return async (dispatch, getState) => {
    try {
      const post = selectPostPreviewResource(getState(), id);

      if (!options?.shouldInvalidate && post) {
        return post.data;
      }

      dispatch(postPreviewRequestPending({ key: id }));

      const response = await getPostPreviewById(id);

      dispatch(postPreviewRequestFulfilled({ key: id, value: response.data }));

      return response.data;
    } catch (error) {
      dispatch(postPreviewRequestRejected({ key: id }));

      return [];
    }
  };
}

export function getStockThunk(
  id: Nullable<string>,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<Stock>>> {
  return async (dispatch, getState) => {
    try {
      if (!id) return null;

      const stock = selectStock(getState(), id);
      if (!options?.shouldInvalidate && stock) {
        return stock;
      }

      const response = await getStock(id);

      dispatch(
        stockAdded({
          key: id,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function getInvestmentThunk(
  id: Nullable<string>,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<Investment>>> {
  return async (dispatch, getState) => {
    try {
      if (!id) return null;

      const investment = selectInvestment(getState(), id);
      if (!options?.shouldInvalidate && investment) {
        return investment;
      }

      const response = await getInvestment(id);

      dispatch(
        investmentAdded({
          key: id,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function selectPostResource(
  state: AppState,
  urlAlias: string
): ResourceType<Nullable<PostFull>> | undefined {
  return state.pages.post.postMap[urlAlias];
}

export function selectPostPreviewResource(
  state: AppState,
  id: number
): ResourceType<Nullable<Array<PostShort>>> | undefined {
  return state.pages.post.postPreviewMap[id];
}

export function selectPost(
  state: AppState,
  urlAlias: string
): Nullable<PostFull> {
  return selectPostResource(state, urlAlias)?.data ?? null;
}

export function selectStock(
  state: AppState,
  id: Nullish<string>
): Nullable<Stock> {
  if (!id) return null;

  return state.pages.post.stockMap[id] ?? null;
}

export function selectInvestment(
  state: AppState,
  id: Nullish<string>
): Nullable<Investment> {
  if (!id) return null;

  return state.pages.post.investmentMap[id] ?? null;
}
