import React, { useEffect } from 'react';
import * as Sentry from '@sentry/node';
import Head from 'next/head';
import App from 'next/app';
import { useRouter } from 'next/router';

import ModalProvider from '@components/Modal/Modal';
import { useAnalytics } from '@tager/web-analytics';
import { AdminBar } from '@tager/web-panel';
import {
  ACCESS_TOKEN_COOKIE,
  api,
  cookie,
  isServer,
  REFRESH_TOKEN_COOKIE,
  useProgressBar,
} from '@tager/web-core';

import '@/assets/css/index.css';
import withRedux from '@/hocs/withRedux';
import { CustomApp_AppContext, CustomApp_Component } from '@/typings/hocs';
import { LangType } from '@/typings/common';
import { I18nProvider } from '@/components/I18nProvider';
import { getLangFromUrl } from '@/utils/common';
import { checkAuthorizationThunk } from '@/store/reducers/auth';

Sentry.init({
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_ENV !== 'local',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    process.env.NEXT_PUBLIC_ENV,
  ].join('_'),
});

/**
 * Custom App documentation
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp: CustomApp_Component = (props) => {
  useProgressBar({ showSpinner: false });
  useAnalytics();

  // const router = useRouter();
  //
  // useEffect(() => {
  //   console.log('router.asPath', router.asPath);
  // }, [router.asPath]);

  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // @ts-ignore
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };
  return (
    <>
      <AdminBar />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <I18nProvider initialLanguage={props.lang}>
        <ModalProvider>
          <Component {...modifiedPageProps} />
        </ModalProvider>
      </I18nProvider>
    </>
  );
};

/**
 * Only use this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * Reference: https://nextjs.org/docs/advanced-features/custom-app
 */
CustomApp.getInitialProps = async (appContext) => {
  /** calls page's `getInitialProps` and fills `appProps.pageProps` */
  const { ctx } = appContext;

  const lang: LangType = getLangFromUrl(appContext.ctx.asPath);

  const updatedAppContext: CustomApp_AppContext = {
    ...appContext,
    ctx: { ...appContext.ctx, lang },
  };
  if (isServer() && ctx.req) {
    const accessToken = cookie.get(ACCESS_TOKEN_COOKIE, ctx.req);
    const refreshToken = cookie.get(REFRESH_TOKEN_COOKIE, ctx.req);

    api.setAccessToken(accessToken);
    api.setRefreshToken(refreshToken);
    api.setConfig({ useRefreshToken: true });

    await ctx.store.dispatch(checkAuthorizationThunk());
  }
  const appProps = await App.getInitialProps(updatedAppContext);

  return { ...appProps, lang };
};

export default withRedux(CustomApp);
