import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createResourceLoader,
  FETCH_STATUSES,
  FetchStatus,
  MapEntry,
  Nullable,
  Nullish,
  ResourceType,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getCategory, getPostListByCategory } from '@/services/requests';
import { CategoryFull, PostShort } from '@/typings/model';
import { LangType } from '@/typings/common';

type CategoryId = string;
type CategoryAliasAndLang = string;

type PostListResource = ResourceType<
  Array<PostShort>,
  FetchStatus | 'LOADING_MORE'
> & { hasMore: boolean };

const categoryLoader = createResourceLoader<Nullable<CategoryFull>>(null);

type State = {
  categoryMap: Record<
    CategoryAliasAndLang,
    ResourceType<Nullable<CategoryFull>>
  >;
  postListMap: Record<CategoryId, PostListResource>;
};

const initialState: State = {
  categoryMap: {},
  postListMap: {},
};

const categorySlice = createSlice({
  name: 'pages/category',
  initialState,
  reducers: {
    categoryRequestPending(state, action: PayloadAction<{ key: string }>) {
      state.categoryMap[action.payload.key] = categoryLoader.pending();
    },
    categoryRequestFulfilled(
      state,
      action: PayloadAction<MapEntry<CategoryAliasAndLang, CategoryFull>>
    ) {
      state.categoryMap[action.payload.key] = categoryLoader.fulfill(
        action.payload.value
      );
    },

    categoryRequestRejected(state, action: PayloadAction<{ key: string }>) {
      state.categoryMap[action.payload.key] = categoryLoader.reject();
    },

    postListByCategoryRequestPending(
      state,
      action: PayloadAction<{ key: CategoryId }>
    ) {
      state.postListMap[action.payload.key] = {
        data: [],
        status: FETCH_STATUSES.LOADING,
        error: null,
        hasMore: true,
        meta: null,
      };
    },
    postListByCategoryRequestFulfilled(
      state,
      action: PayloadAction<{
        entry: MapEntry<CategoryId, Array<PostShort>>;
        hasMore: boolean;
      }>
    ) {
      state.postListMap[action.payload.entry.key] = {
        data: action.payload.entry.value,
        status: FETCH_STATUSES.SUCCESS,
        error: null,
        hasMore: action.payload.hasMore,
        meta: null,
      };
    },
    postListByCategoryRequestRejected(
      state,
      action: PayloadAction<{ key: CategoryId }>
    ) {
      state.postListMap[action.payload.key] = {
        data: [],
        status: FETCH_STATUSES.FAILURE,
        error: null,
        hasMore: false,
        meta: null,
      };
    },

    postListByCategoryChunkRequestPending(
      state,
      action: PayloadAction<{ key: CategoryId }>
    ) {
      state.postListMap[action.payload.key].status = 'LOADING_MORE';
    },

    postListChunkRequestFulfilled(
      state,
      action: PayloadAction<{
        entry: MapEntry<CategoryId, Array<PostShort>>;
        hasMore: boolean;
      }>
    ) {
      state.postListMap[action.payload.entry.key] = {
        data: [
          ...state.postListMap[action.payload.entry.key].data,
          ...action.payload.entry.value,
        ],
        status: FETCH_STATUSES.SUCCESS,
        error: null,
        hasMore: action.payload.hasMore,
        meta: null,
      };
    },

    postListByCategoryChunkRequestRejected(
      state,
      action: PayloadAction<{ key: CategoryId }>
    ) {
      state.postListMap[action.payload.key] = {
        data: [],
        status: FETCH_STATUSES.FAILURE,
        error: null,
        hasMore: false,
        meta: null,
      };
    },
  },
});

const { actions, reducer } = categorySlice;
export const {
  categoryRequestPending,
  categoryRequestFulfilled,
  categoryRequestRejected,
  postListByCategoryRequestPending,
  postListByCategoryRequestFulfilled,
  postListByCategoryRequestRejected,
  postListByCategoryChunkRequestPending,
  postListChunkRequestFulfilled,
  postListByCategoryChunkRequestRejected,
} = actions;

export default reducer;

export function getCategoryThunk(
  categoryAlias: string,
  lang: LangType,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk<Promise<Nullable<CategoryFull>>> {
  return async (dispatch, getState) => {
    try {
      const category = selectCategoryResourceByAliasAndLang(
        getState(),
        categoryAlias,
        lang
      );

      if (!options?.shouldInvalidate && category) {
        return category.data;
      }

      dispatch(categoryRequestPending({ key: categoryAlias + '_' + lang }));
      const response = await getCategory(categoryAlias, lang);

      dispatch(
        categoryRequestFulfilled({
          key: categoryAlias + '_' + lang,
          value: response.data,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(categoryRequestRejected({ key: categoryAlias + '_' + lang }));
      return null;
    }
  };
}

export function getPostListByCategoryThunk(
  categoryId: number,
  options?: {
    shouldInvalidate?: boolean;
  }
): AppThunk {
  return async (dispatch, getState) => {
    if (!categoryId) {
      throw new Error(`Category id is empty`);
    }

    const categoryIdString = String(categoryId);
    try {
      const postList = selectPostListResourceByCategoryId(
        getState(),
        categoryId
      );
      if (!options?.shouldInvalidate && postList) {
        return postList;
      }
      dispatch(postListByCategoryRequestPending({ key: categoryIdString }));

      const limit = 5;

      const response = await getPostListByCategory(categoryId, {
        offset: 0,
        limit: limit + 1,
      });

      const hasMore = response.data.length > limit;

      dispatch(
        postListByCategoryRequestFulfilled({
          entry: {
            key: categoryIdString,
            value: response.data.slice(0, limit),
          },
          hasMore,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(postListByCategoryRequestRejected({ key: categoryIdString }));
      return error;
    }
  };
}

export function getPostListByCategoryChunkThunk(
  categoryId: number | undefined
): AppThunk {
  return async (dispatch, getState) => {
    if (!categoryId) return [];

    const categoryIdString = String(categoryId);
    try {
      const postList = selectPostListResourceByCategoryId(
        getState(),
        categoryId
      );
      dispatch(
        postListByCategoryChunkRequestPending({ key: categoryIdString })
      );

      const limit = 5;

      const response = await getPostListByCategory(categoryId, {
        offset: postList.data.length,
        limit: limit + 1,
      });

      const hasMore = response.data.length > limit;

      dispatch(
        postListChunkRequestFulfilled({
          entry: {
            key: categoryIdString,
            value: response.data.slice(0, limit),
          },
          hasMore,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(
        postListByCategoryChunkRequestRejected({ key: categoryIdString })
      );

      return error;
    }
  };
}

export function selectCategoryResourceByAliasAndLang(
  state: AppState,
  categoryAlias: string,
  lang: LangType
): ResourceType<Nullable<CategoryFull>> | undefined {
  return state.pages.category.categoryMap[categoryAlias + '_' + lang];
}

export function selectCategoryByAliasAndLang(
  state: AppState,
  categoryAlias: string,
  lang: LangType
): Nullable<CategoryFull> {
  return (
    selectCategoryResourceByAliasAndLang(state, categoryAlias, lang)?.data ??
    null
  );
}

export function selectPostListResourceByCategoryId(
  state: AppState,
  categoryId: Nullish<number>
): PostListResource {
  const categoryIdString = String(categoryId);
  return state.pages.category.postListMap[categoryIdString];
}
