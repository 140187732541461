export const EN = {
  licenseAgreement: 'License agreement',
  addBookmark: 'Add bookmark',
  removeBookmarks: 'Remove bookmarks',
  pageNotFound: 'Page not found',
  thisPageDoesNotExist: 'This page does not exist',
  returnToHome: 'return to home',
  day: 'Day',
  night: 'Night',
  saved: 'Saved',
  savedArticles: 'Saved articles',
  noArticles: 'No articles',
  today: 'today',
  dayAgo: 'day ago',
  secondDayAgo: 'days ago',
  daysAgo: 'days ago',
  weekAgo: 'week ago',
  secondWeeksAgo: 'weeks ago',
  weeksAgo: 'weeks ago',
  monthAgo: 'month ago',
  secondMonthsAgo: 'months ago',
  monthsAgo: 'months ago',
  search: 'Search',
  personalAccount: 'PERSONAL ACCOUNT',
  logout: 'Logout',
  logIn: 'Log In',
  login: 'Login',
  signUp: 'Sign up',
  signUpButton: 'Sign up',
  restorePasswordButton: 'Restore password',
  resetPassword: 'Reset password',
  restorePassword: 'Restore password',
  password: 'Password',
  email: 'Email',
  mail: 'Email',
  name: 'Name',
  surname: 'Surname',
  phone: 'Phone',
  restoreText:
    "We've send you a link to reset password. Please, follow instructions",
  code: 'Code',
  emptyLogin: 'You must fill in the «Name».',
  emptyLoginEmail: 'You must fill in the «LogIn/Email».',
  emptyPassword: 'You must fill in the «Password».',
  emptyEmail: 'You must fill in the «Email».',
  emptyName: 'You must fill in the «Name».',
  emptySurname: 'You must fill in the «Surname».',
  emptyPhone: 'You must fill in the «Phone».',
  emptyCode: 'You must fill in the «Code».',
  noValidEmail: 'The value «Email» is not a valid email address. ',
  littlePassword:
    'Password should have length between 8 and 100 and contain at list 1 capital, 1 number and 1 lower letter',
  phoneInvalid: 'Invalid phone format. Phone must start with "+"',
  codeInvalid: 'Invalid activation code',
  emailUsed: 'Email already used',
  loginInvalid: 'Invalid login or password',
  clickReg: 'Clicking "sign up" you accept',
  conditionsUser: 'conditions of user agreement',
  promotions: 'Promotions',
  invest: 'Invest',
  investIn: 'Invest in',
  capitalization: 'Capitalization',
  changeOverTheYear: 'Change over the year',
  applicationsBefore: 'Applications before',
  finish: 'Finish',
  commissions: 'Commissions',
  enter: 'Enter',
  leave: 'Leave',
  profit: 'Profit',
  management: 'Management',
  OtherArticles: 'Other articles',
};

export const RU: typeof EN = {
  licenseAgreement: 'Лицензионное соглашение',
  addBookmark: 'Добавить в закладки',
  removeBookmarks: 'Удалить закладку',
  pageNotFound: 'Страница не найдена',
  thisPageDoesNotExist: 'Данная страница не существует',
  returnToHome: 'вернитесь на главную',
  day: 'День',
  night: 'Ночь',
  saved: 'Сохраненные',
  savedArticles: 'Сохраненные записи',
  noArticles: 'Нет записей',
  today: 'сегодня',
  dayAgo: 'день назад',
  secondDayAgo: 'дня назад',
  daysAgo: 'дней назад',
  weekAgo: 'неделю назад',
  secondWeeksAgo: 'недели назад',
  weeksAgo: 'недель назад',
  monthAgo: 'месяц назад',
  secondMonthsAgo: 'месяца назад',
  monthsAgo: 'месяцев назад',
  search: 'Поиск',
  personalAccount: 'ЛИЧНЫЙ КАБИНЕТ',
  logout: 'Выйти',
  logIn: 'Войти',
  login: 'Логин',
  signUp: 'Регистрация',
  signUpButton: 'Зарегестрироваться',
  restorePasswordButton: 'Восстановить пароль',
  resetPassword: 'Сбросить пароль',
  restorePassword: 'Восстановление пароля',
  password: 'Пароль',
  email: 'Эл. почта',
  mail: 'Почта',
  name: 'Имя',
  surname: 'Фамилия',
  phone: 'Телефон',
  restoreText:
    'Мы отправили ссылку для сброса пароля. Пожалуйста, следуйте инструкциям из письма',
  code: 'Код',
  emptyLogin: 'Необходимо заполнить «Логин».',
  emptyLoginEmail: 'Необходимо заполнить «Логин/Почта».',
  emptyPassword: 'Необходимо заполнить «Пароль».',
  emptyEmail: 'Необходимо заполнить «Электронная почта».',
  emptyName: 'Необходимо заполнить «Имя».',
  emptySurname: 'Необходимо заполнить «Фамилия».',
  emptyPhone: 'Необходимо заполнить «Телефон».',
  emptyCode: 'Необходимо заполнить «Код».',
  noValidEmail:
    'Значение «Электронная почта» не является правильным email адресом.',
  littlePassword:
    'Пароль должен иметь длину от 8 до 100 и содержать не менее 1 заглавной буквы, 1 цифры и 1 строчной буквы',
  phoneInvalid: 'Неверный формат телефона. Телефон должен начинаться с "+"',
  codeInvalid: 'Введен неверный код активации',
  emailUsed: 'Такая Эл. почта уже существует',
  loginInvalid: 'Неверный логин или пароль',
  clickReg: 'По нажатию на "Зарегистрироваться" вы принимаете',
  conditionsUser: 'условия пользовательского соглашения',
  promotions: 'Акции',
  invest: 'Инвестировать',
  investIn: 'Инвестировать в',
  capitalization: 'Капитализация',
  changeOverTheYear: 'Изменение за год',
  applicationsBefore: 'Заявки до',
  finish: 'Финиш',
  commissions: 'Комиссии',
  enter: 'На вход',
  leave: 'На выход',
  profit: 'На прибыль',
  management: 'На менеджмент',
  OtherArticles: 'Другие статьи',
};

export type TranslationKey = keyof typeof EN;
