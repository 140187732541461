import { combineReducers } from 'redux';

import authReducer from './auth';
import settingsReducer from './tager/settings';
import bannersReducer from './tager/banners';
import menusReducer from './tager/menus';
import pagesReducer from './tager/pages';
import categoryReducer from './pages/category';
import postReducer from './pages/post';
import homeReducer from './pages/home';
import searchReducer from './pages/search';
import bookmarksReducer from './pages/bookmarks';

const tagerReducer = combineReducers({
  banners: bannersReducer,
  menus: menusReducer,
  pages: pagesReducer,
  settings: settingsReducer,
});

const pageDataReducer = combineReducers({
  category: categoryReducer,
  post: postReducer,
  home: homeReducer,
  search: searchReducer,
  bookmarks: bookmarksReducer,
});

const rootReducer = combineReducers({
  tager: tagerReducer,
  pages: pageDataReducer,
  auth: authReducer,
});

export default rootReducer;
