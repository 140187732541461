import React, { useCallback, useMemo, useState } from 'react';

import { createContextHookWithProvider, Nullable } from '@tager/web-core';

import { LangType } from '@/typings/common';
import { EN, RU, TranslationKey } from '@/constants/locales';

type TFunction = (translation: TranslationKey) => Nullable<string>;

export type I18nContextType = {
  language: LangType;
  changeLanguage: (newLanguage: LangType) => void;
  t: TFunction;
};

const contextResult = createContextHookWithProvider<I18nContextType>(
  'I18nContext'
);

export const useI18nContext = contextResult[0];
export const I18nContextProvider = contextResult[1];

export function useTranslation() {
  return useI18nContext();
}

type Props = {
  initialLanguage: LangType;
  children?: React.ReactNode;
};

export function I18nProvider({ initialLanguage, children }: Props) {
  const [language, setLanguage] = useState<LangType>(initialLanguage);

  const t = useCallback<TFunction>(
    (key) => {
      const translations = language === 'en' ? EN : RU;
      return translations[key];
    },
    [language]
  );

  const context = useMemo<I18nContextType>(
    () => ({ language, t, changeLanguage: setLanguage }),
    [language, t]
  );

  return <I18nContextProvider value={context}>{children}</I18nContextProvider>;
}
