import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getBookmarks, getUserBookmarks } from '@/services/requests';
import { PostShort } from '@/typings/model';
import { LangType, PostBookmark } from '@/typings/common';
import { selectIsUserAuthorized } from '@/store/reducers/auth';

const bookmarksLoader = createResourceLoader<Array<PostShort>>([]);

type State = {
  bookmarks: ResourceType<Array<PostShort>>;
};

const initialState: State = {
  bookmarks: bookmarksLoader.getInitialResource(),
};

const bookmarksSlice = createSlice({
  name: 'pages/bookmarks',
  initialState,
  reducers: {
    bookmarksRequestPending(state) {
      state.bookmarks = bookmarksLoader.pending();
    },
    bookmarksRequestFulfilled(state, action: PayloadAction<Array<PostShort>>) {
      state.bookmarks = bookmarksLoader.fulfill(action.payload);
    },
    bookmarksRequestRejected(state) {
      state.bookmarks = bookmarksLoader.reject();
    },
  },
});

const { actions, reducer } = bookmarksSlice;

export const {
  bookmarksRequestPending,
  bookmarksRequestFulfilled,
  bookmarksRequestRejected,
} = actions;

export default reducer;

export function getBookmarksThunk(): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    try {
      const isUserAuthorized = selectIsUserAuthorized(getState());
      if (!isUserAuthorized) return [];
      dispatch(bookmarksRequestPending());
      const bookmarkList = await getUserBookmarks();

      if (bookmarkList.data.length <= 0) {
        dispatch(bookmarksRequestRejected());
        return [];
      }

      const arrayIds = bookmarkList.data.map((bookmark) => bookmark.postId);

      const responses = await getBookmarks(arrayIds.join());

      dispatch(bookmarksRequestFulfilled(responses.data));

      return responses.data;
    } catch (error) {
      dispatch(bookmarksRequestRejected());
      return [];
    }
  };
}

export function checkUserBookmarksThunk(
  currentBookmark: PostBookmark
): AppThunk<Promise<boolean>> {
  return async () => {
    try {
      const responses = await getUserBookmarks();

      return responses.data.some(
        (bookmark) => bookmark.postId === currentBookmark.id
      );
    } catch (error) {
      return false;
    }
  };
}

export function selectBookmarksResource(
  state: AppState
): ResourceType<Array<PostShort>> {
  return state.pages.bookmarks.bookmarks;
}
