import React from 'react';

import { getUserBookmarks } from '@services/requests';
import { Nullish, RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { LangType, PostBookmark } from '@/typings/common';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function getPostAliasAndIdFromUrlAlias(
  urlAlias: string
): [number, string] {
  const hyphenIndex = urlAlias.indexOf('-');
  const id = urlAlias.slice(0, hyphenIndex - 1);
  const alias = urlAlias.slice(hyphenIndex + 1);

  return [Number(id), alias];
}

export function getAliasWithoutId(alias: string): string {
  return getPostAliasAndIdFromUrlAlias(alias)[1];
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

/** Post bookmarks */

const BOOKMARKS_KEY = 'post_bookmarks';

export function withLang(alias: string, lang: LangType): string {
  return alias + '_' + lang;
}

export const LOCALE_SUBPATHS: Record<string, string> = {
  en: '/en',
};

export const DEFAULT_LANGUAGE: LangType = 'ru';

export function getSubpathByLang(lang: Nullish<string>): string {
  if (!lang) return '';

  return LOCALE_SUBPATHS[lang] ?? '';
}

export function addSubpathToUrl(url: string, lang: LangType): string {
  const subpath = getSubpathByLang(lang);

  return subpath + url;
}

export function getLangFromUrl(url: Nullish<string>): LangType {
  if (!url) return DEFAULT_LANGUAGE;

  for (const [lang, subpath] of Object.entries(LOCALE_SUBPATHS)) {
    if (url.startsWith(subpath)) return lang as LangType;
  }

  return DEFAULT_LANGUAGE;
}
