import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createResourceLoader, ResourceType } from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { searchPost } from '@/services/requests';
import { PostShort } from '@/typings/model';
import { LangType } from '@/typings/common';

const searchLoader = createResourceLoader<Array<PostShort>>([]);

type StateType = {
  search: ResourceType<Array<PostShort>>;
};

const initialState: StateType = {
  search: searchLoader.getInitialResource(),
};

const searchSlice = createSlice({
  name: 'pages/search',
  initialState,
  reducers: {
    searchRequestPending(state) {
      state.search = searchLoader.pending();
    },
    searchRequestFulfilled(state, action: PayloadAction<Array<PostShort>>) {
      state.search = searchLoader.fulfill(action.payload);
    },
    searchRequestRejected(state) {
      state.search = searchLoader.reject();
    },
    searchRequestCancel(state) {
      state.search = searchLoader.cancel();
    },
  },
});

const { actions, reducer } = searchSlice;

export const {
  searchRequestPending,
  searchRequestFulfilled,
  searchRequestRejected,
  searchRequestCancel,
} = actions;

export default reducer;

export function searchThunk(
  lang: LangType,
  query: string
): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    if (!query) {
      dispatch(searchRequestCancel());
      return [];
    }

    try {
      dispatch(searchRequestPending());
      const response = await searchPost({
        lang,
        query,
        offset: 0,
        limit: 10,
      });
      dispatch(searchRequestFulfilled(response.data));

      return response.data;
    } catch (error) {
      dispatch(searchRequestRejected());
      return [];
    }
  };
}

export function selectFindPostListResource(
  state: AppState
): ResourceType<Array<PostShort>> {
  return state.pages.search.search;
}
