import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FETCH_STATUSES,
  FetchStatus,
  Nullable,
  ResourceType,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getIndexPageSeoParams, getPostList } from '@/services/requests';
import { IndexPageSeoParams, PostShort } from '@/typings/model';
import { LangType } from '@/typings/common';

type PostListResource = ResourceType<
  Array<PostShort>,
  FetchStatus | 'LOADING_MORE'
> & { hasMore: boolean };

type StateType = {
  postList: PostListResource;
  seoParams: Nullable<IndexPageSeoParams>;
};

const initialState: StateType = {
  postList: {
    data: [],
    status: FETCH_STATUSES.IDLE,
    error: null,
    hasMore: true,
    meta: null,
  },
  seoParams: null,
};

const homeSlice = createSlice({
  name: 'pages/home',
  initialState,
  reducers: {
    postListRequestPending(state) {
      state.postList = {
        ...state.postList,
        data: [],
        status: FETCH_STATUSES.LOADING,
        error: null,
      };
    },
    postListRequestFulfilled(
      state,
      action: PayloadAction<{ data: Array<PostShort>; hasMore: boolean }>
    ) {
      state.postList = {
        data: action.payload.data,
        status: FETCH_STATUSES.SUCCESS,
        error: null,
        hasMore: action.payload.hasMore,
        meta: null,
      };
    },
    postListRequestRejected(state) {
      state.postList = {
        data: [],
        status: FETCH_STATUSES.FAILURE,
        error: null,
        hasMore: false,
        meta: null,
      };
    },
    postListChunkRequestPending(state) {
      state.postList.status = 'LOADING_MORE';
    },
    postListChunkRequestFulfilled(
      state,
      action: PayloadAction<{ data: Array<PostShort>; hasMore: boolean }>
    ) {
      state.postList = {
        data: [...state.postList.data, ...action.payload.data],
        status: FETCH_STATUSES.SUCCESS,
        error: null,
        hasMore: action.payload.hasMore,
        meta: null,
      };
    },
    postListChunkRequestRejected(state) {
      state.postList = {
        data: [],
        status: FETCH_STATUSES.FAILURE,
        error: null,
        hasMore: false,
        meta: null,
      };
    },
    seoParamsRequestFulfilled(
      state,
      action: PayloadAction<IndexPageSeoParams>
    ) {
      state.seoParams = action.payload;
    },
  },
});

const { actions, reducer } = homeSlice;

export const {
  postListRequestPending,
  postListRequestFulfilled,
  postListRequestRejected,
  postListChunkRequestPending,
  postListChunkRequestFulfilled,
  postListChunkRequestRejected,
  seoParamsRequestFulfilled,
} = actions;

export default reducer;

export function getHomePostListThunk(
  lang: LangType
): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    // console.log('lang:', lang);
    try {
      dispatch(postListRequestPending());

      const limit = 5;

      const response = await getPostList({
        lang,
        offset: 0,
        limit: limit + 1,
      });

      const hasMore = response.data.length > limit;
      dispatch(
        postListRequestFulfilled({
          data: response.data.slice(0, limit),
          hasMore,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(postListRequestRejected());
      return [];
    }
  };
}

export function getNextHomePostListThunk(
  lang: LangType
): AppThunk<Promise<Array<PostShort>>> {
  return async (dispatch, getState) => {
    try {
      dispatch(postListChunkRequestPending());

      const postList = selectPostListResource(getState());

      const limit = 5;

      const response = await getPostList({
        lang,
        offset: postList.data.length,
        limit: limit + 1,
      });

      const hasMore = response.data.length > limit;

      dispatch(
        postListChunkRequestFulfilled({
          data: response.data.slice(0, limit),
          hasMore,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(postListChunkRequestRejected());
      return [];
    }
  };
}

export function getIndexPageSeoParamsThunk(
  lang: LangType
): AppThunk<Promise<Nullable<IndexPageSeoParams>>> {
  return async (dispatch, getState) => {
    try {
      const response = await getIndexPageSeoParams(lang);
      dispatch(seoParamsRequestFulfilled(response.data));
      return response.data;
    } catch (error) {
      return null;
    }
  };
}

export function selectPostListResource(state: AppState): PostListResource {
  return state.pages.home.postList;
}

export function selectIndexPageSeoParams(
  state: AppState
): Nullable<IndexPageSeoParams> {
  return state.pages.home.seoParams;
}
