import { request, ResponseBody } from '@tager/web-core';

import { LangType } from '@/typings/common';
import {
  CategoryFull,
  IndexPageSeoParams,
  Investment,
  PostFull,
  PostShort,
  Stock,
  UserProfileType,
} from '@/typings/model';

export function getCategory(
  categoryAlias: string,
  lang: LangType
): Promise<ResponseBody<CategoryFull>> {
  return request.get({
    path: `/tager/blog/categories/${categoryAlias}`,
    params: { lang },
  });
}

export function getCategoryListByLang(
  lang: LangType
): Promise<ResponseBody<Array<CategoryFull>>> {
  return request.get({ path: '/tager/blog/categories', params: { lang } });
}

export function getIndexPageSeoParams(
  lang: LangType
): Promise<ResponseBody<IndexPageSeoParams>> {
  return request.get({ path: `/tager/seo/template/blog_index_${lang}` });
}

export function getPostList(params: {
  lang: LangType;
  offset: number;
  limit: number;
}): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: '/tager/blog/posts',
    params,
  });
}
export function getPostListByLang(
  lang: LangType
): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: '/tager/blog/posts',
    params: { lang },
  });
}
export function getPostListByCategory(
  categoryId: number | string,
  params: { offset: number; limit: number }
): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: `/tager/blog/categories/${categoryId}/posts`,
    params,
  });
}

export function getPost(
  postAlias: string,
  lang: LangType
): Promise<ResponseBody<PostFull>> {
  return request.get({
    path: `/tager/blog/posts/view/${postAlias}`,
    params: { lang },
  });
}

export function getStock(id: string): Promise<ResponseBody<Stock>> {
  return request.get({
    path: `/stocks/${id}`,
  });
}

export function getInvestment(id: string): Promise<ResponseBody<Investment>> {
  return request.get({
    path: `/investments/${id}`,
  });
}

export function getBookmarks(
  ids: string
): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: `/tager/blog/posts/`,
    params: { ids },
  });
}

export function getPostPreviewById(
  ids: number
): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: `/tager/blog/posts/`,
    params: { ids },
  });
}

export function searchPost(params: {
  lang: LangType;
  query: string;
  offset: number;
  limit: number;
}): Promise<ResponseBody<Array<PostShort>>> {
  return request.get({
    path: `/tager/blog/posts/search`,
    params,
  });
}
export type SignInPayload = {
  login: string;
  password: string;
};

export type RegisterPayload = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  code?: number;
  uuid?: string;
  login?: string;
  password?: string;
};

export type OAuthTokenResponseBody = {
  accessToken: string;
  refreshToken: string;
  profile: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  };
};

export function signIn(
  payload: SignInPayload
): Promise<ResponseBody<OAuthTokenResponseBody>> {
  return request.post({
    path: '/auth',
    body: { ...payload },
  });
}

export function getUserProfile(): Promise<ResponseBody<UserProfileType>> {
  return request.get({ path: '/user/profile' });
}

export function sendRegisterLink(
  payload: RegisterPayload
): Promise<ResponseBody<{ uuid: string }>> {
  return request.post({ path: '/register', body: { ...payload } });
}

export function sendVerify(
  code: string,
  uuid: string
): Promise<ResponseBody<{ uuid: string }>> {
  return request.post({ path: '/register/verify', body: { code, uuid } });
}

export function registration(
  login: string,
  password: string,
  uuid: string
): Promise<ResponseBody<{ uuid: string }>> {
  return request.post({
    path: '/register/submit',
    body: { login, password, uuid },
  });
}

export function sendRestoreLink(email: string): Promise<{ success: boolean }> {
  return request.post({ path: '/auth/restore', body: { email } });
}

export function getUserBookmarks(): Promise<
  ResponseBody<Array<{ postId: number }>>
> {
  return request.get({ path: '/user/bookmarks' });
}

export function addUserBookmarks(
  postId: number
): Promise<{ success: boolean }> {
  return request.post({ path: `/user/bookmarks/${postId}` });
}

export function removeUserBookmarks(
  postId: number
): Promise<{ success: boolean }> {
  return request.delete({ path: `/user/bookmarks/${postId}` });
}
