import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--popup-overlay-bg);
  z-index: 100;
  overflow-y: auto;
  padding: 50px 0;
  ${(props) =>
    !props.isOpen
      ? css`
          display: none;
        `
      : ''}
`;

export const ModalContainer = styled.div<{
  width?: number;
  centered?: boolean;
  padding?: string;
}>`
  ${(props) =>
    props.centered
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          position: relative;
          margin: 0 auto;
        `};

  max-width: ${(props) => props.width || 780}px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--main-bg-color);
  padding: ${(props) => props.padding || '40px 0 20px'};

  ${media.mobile(css`
    height: 100%;
    max-width: 100%;
    border: 0;
  `)}
`;
